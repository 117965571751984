import React from 'react'

function About() {
  return (
    <div className='bg-black w-full h-screen text-white font-defmono '>
      <h1 className='text-7xl  pt-10 underline-offset-[12px] underline decoration-2 text-center'>ETCHNEW</h1>

    </div>
  )
}

export default About