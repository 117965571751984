import React from "react";
import cardsImg from "../images/cards.webp";
import Marquee from "react-fast-marquee";
import herovid from "../videos/synth.mp4";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import opticalogo from "../images/optica.png";
import spielogo from "../images/spie.png";
import ieeelogo from "../images/ieee.png";
import wonderlalogo from "../images/wonderla.png";
import football from "../images/neonfootball.jpg";
import glowinthedark from "../images/glowinthedark.jpg";
import carousel from "../images/carousel.jpg";
import cditlogo from "../images/cdit.png";
import derriklogo from "../images/Derrick-Logo (1).png";
import decathlonlogo from "../images/decathlon.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ispimage from "../images/isp1.png";
import cusatimg from "../images/cusat2.png";

function Home() {
  useEffect(() => {
    Aos.init({ duration: 500, delay: 200 });
  });

  const slideAnimation = useAnimation();
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      slideAnimation.start({
        x: 150,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.4,
        },
      });
    }
    if (!inView) {
      slideAnimation.start({
        x: "200vw",
      });
    }
  }, [inView]);

  return (
    <div className="overflow-hidden bg-black text-white font-defmono w-full border-spacing-1 h-full border-white  ">
      <div>
        <div id="/" className=" w-full h-screen  z-0 ">
          <div className=" h-50  border-white">
            <Marquee
              pauseOnHover="true"
              gradient={false}
              speed={60}
              className="border-b border-t border-white p-2 z-0 font-defmono "
            >
              <p className="text-2xl mobile:text-3xl  ">
                |ETCH<span className="text-red-500">NEW</span>|{">"}   |NEON
                FOOTBALL WILL BE CONDUCTED ON JAN 12!| {">"}  |PROJECTS|  {">"} 
                <a href="https://forms.gle/QQMfzRdqNG8QMhVeA/"> |REGISTER| </a>
                {">"} <a href="#contact">|CONTACT US|</a> {">"}
              </p>
            </Marquee>
          </div>
          <div className=" w-full text-6xl pc:text-8xl grid  ">
            <div className=" grid z-1 justify-items-center grid-cols-1 absolute self-center w-full text-center top-1/2 z-50  font-defmono">
              <h1 className="">EtchNew'23</h1>
              <p className="text-2xl tablet:text-4xl ">
                JANUARY 19-20 <br /> 2023
              </p>
              <a
                href="https://forms.gle/QQMfzRdqNG8QMhVeA/"
                className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100  cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700"
              >
                <p>Click Here</p>
              </a>
            </div>
            <video
              src={herovid}
              height="1080"
              width="1920"
              className="video -z-1 "
              loop
              autoPlay
              muted
              type="video/mp4"
            />
          </div>
        </div>
      </div>
      <div id="event">
        <div className="w-full pc:h-screen grid grid-flow-row pc:grid-flow-col tablet:grid-cols-2 items-center justify-evenly ">
          <div className="text-9xl ">
            <div className=" h-full w-screen">
              <div className="grid mt-48  tablet:ml-8  ">
                <svg
                  className=" bg-white m-2 w-3/5 h-auto tablet:w-[30%] "
                  xmlns="http://www.w3.org/2000/svg"
                  width="600.000000pt"
                  height="250.000000pt"
                  viewBox="0 0 600.000000 250.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,250.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path
                      d="M360 1240 l0 -930 575 0 575 0 0 85 0 85 -490 0 -490 0 0 335 0 335
                 490 0 490 0 0 85 0 85 -490 0 -490 0 0 340 0 340 490 0 490 0 0 85 0 85 -575
                 0 -575 0 0 -930z"
                    />
                    <path
                      d="M2110 1905 l0 -265 -95 0 -95 0 0 -75 0 -75 95 0 95 0 0 -436 c0
                 -295 4 -450 12 -482 9 -39 26 -62 107 -143 72 -73 106 -100 136 -108 22 -6
                 106 -11 188 -11 l147 0 0 75 0 75 -142 0 -142 0 -73 73 -73 73 0 442 0 442
                 215 0 215 0 0 75 0 75 -215 0 -215 0 0 265 0 265 -80 0 -80 0 0 -265z"
                    />
                    <path
                      d="M4560 1245 l0 -925 80 0 80 0 0 447 0 446 126 128 c70 70 139 133
                 154 139 18 7 83 10 184 8 l156 -3 70 -70 70 -69 0 -513 0 -513 80 0 81 0 -3
                 528 -3 527 -27 46 c-38 65 -180 196 -226 209 -61 17 -386 13 -430 -5 -20 -9
                 -81 -59 -134 -113 l-98 -97 0 378 0 377 -80 0 -80 0 0 -925z"
                    />
                    <path
                      d="M3272 1629 c-42 -12 -184 -147 -220 -209 l-27 -45 0 -400 0 -400 27
                 -46 c38 -65 180 -196 226 -209 21 -6 157 -10 322 -10 341 0 323 -5 460 134
                 l94 96 -54 55 -55 56 -86 -93 -86 -93 -273 -3 -273 -3 -73 74 -74 73 0 369 0
                 369 74 73 73 74 273 -3 273 -3 86 -93 86 -93 55 56 54 55 -94 96 c-137 139
                 -118 134 -465 133 -159 0 -305 -5 -323 -10z"
                    />
                  </g>
                </svg>
              </div>
              <div ref={ref} className=" m-2 mr-4">
                <motion.div animate={slideAnimation}>
                  <svg
                    className="bg-white  w-3/5 h-auto tablet:w-[30%]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="600.000000pt"
                    height="250.000000pt"
                    viewBox="0 0 600.000000 250.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,250.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path
                        d="M2230 1250 l0 -930 575 0 575 0 0 85 0 85 -490 0 -490 0 0 335 0 335
                   490 0 490 0 0 85 0 85 -490 0 -490 0 0 340 0 340 490 0 490 0 0 85 0 85 -575
                   0 -575 0 0 -930z"
                      />
                      <path
                        d="M190 1245 l0 -925 85 0 85 0 0 842 c0 463 3 838 7 835 3 -4 199 -382
                   434 -840 l427 -832 141 -3 141 -3 0 926 0 925 -85 0 -85 0 -2 -839 -3 -839
                   -430 839 -430 839 -142 0 -143 0 0 -925z"
                      />
                      <path
                        d="M3830 2163 c0 -5 85 -420 188 -923 l188 -915 127 -3 c124 -3 127 -2
                   132 20 3 13 82 394 175 847 94 454 172 826 174 829 3 2 8 -11 11 -29 4 -19 82
                   -401 173 -849 l167 -815 126 -3 c125 -3 127 -2 132 20 14 56 377 1805 377
                   1816 0 10 -21 12 -77 10 l-78 -3 -172 -835 c-95 -459 -172 -839 -173 -844 0
                   -5 -2 -7 -4 -5 -2 2 -77 375 -166 829 -88 454 -163 833 -166 843 -5 15 -20 17
                   -139 17 l-133 0 -12 -52 c-6 -29 -83 -408 -171 -842 -87 -434 -161 -791 -163
                   -793 -1 -2 -79 377 -172 842 l-169 845 -87 0 c-49 0 -88 -3 -88 -7z"
                      />
                    </g>
                  </svg>
                </motion.div>
              </div>
            </div>
          </div>
          <div className="grid items-center p-8 pc:pr-8 h-full text-justify">
            <div className="border-t-2 border-white  w-full mb-4 tablet:hidden"></div>
            <p className="text-lg pc:text-2xl text-white self-center tablet:pt-48">
              Etchnew is a two-day event that will consist of exhibitions,
              workshops, lecture series, games, and activities that will be held
              at ISP. It's scheduled to be held on 19th and 20th January, 2023.
              Around 300+ schools will be invited for the event with an expected
              footfall of 2000+ students.
              <p className="hidden tablet:visible">
                Etchnew will be preceded by promotional events including a neon
                party, neon football tournament, and other engaging activities
                held at CUSAT. The promotional events have an expected footfall
                of 3000+ CUSATians. ETCHNEW was introduced with the sole purpose
                to promote the importance of Optics and Photonics in our
                community and make them aware of the exciting technological
                breakthroughs of Optics.
              </p>
            </p>
          </div>
        </div>
        <div
          id="ISP&CUSAT"
          className="w-screen tablet:h-screen grid grid-flow-row tablet:grid-flow-col  pc:grid-cols-2 items-center "
        >
          <div
            className=" pc:grid items-center grid-flow-col  h-fit hidden pc:visible   "
            data-aos="fade-right"
            data-aos-easing="ease-in-quart"
            data-aos-duration="300"
            data-aos-delay="500"
          >
            <img
              src={cusatimg}
              alt=""
              className="w-[300px] pc:w-[80%] pc:h-auto  bg-white rounded-xl m-4 justify-self-center   "
              data-aos="fade-right"
              data-aos-easing="ease-in-quart"
              data-aos-duration="500"
              data-aos-delay="500"
            />
            <img
              src={ispimage}
              alt=""
              className="w-[300px] pc:w-[80%] pc:h-auto  bg-white rounded-xl m-4 justify-self-center    "
              data-aos="fade-right"
              data-aos-easing="ease-in-quart"
              data-aos-duration="500"
              data-aos-delay="300"
            />
          </div>
          <div
            className="tablet:pr-10 p-5 self-center gap-4   grid items-center"
            data-aos="fade-left"
            data-aos-easing="ease-in-quart"
            data-aos-duration="300"
            data-aos-delay="1000"
          >
            <div className="tablet:p-3 w-full h-auto ">
              <div className=" grid grid-flow-col gap-0 items-center    bg-white text-black w-full mb-4 ">
                <h1 className="text-2xl tablet:text-5xl underline decoration-2 underline-offset-8 pb-4 p-4">
                  About ISP
                </h1>
                <a
                  className="tablet:ml-2 justify-self-end mr-4"
                  href="https://photonics.cusat.ac.in/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="  "
                    height="48"
                    width="48"
                  >
                    <path d="m24 40-2.1-2.15L34.25 25.5H8v-3h26.25L21.9 10.15 24 8l16 16Z" />
                  </svg>
                </a>
              </div>
              <img
                src={ispimage}
                alt=""
                className="w-[300px] pc:w-[80%] pc:h-auto pc:hidden self-center m-auto   bg-white rounded-2xl pc:m-4 justify-self-center pc:mr-8 p-2 mb-2   "
              />
              <p className="  text-justify pc:text-xl pt-4">
                International School of Photonics (ISP) came in to existence in
                1995 by restructuring and delinking the laser laboratories along
                with the faculty members of the laser group from the Department
                of Physics at Cochin University of Science and Technology.
                Manpower development and establishment of research activities in
                photonics and related fields are the major objectives of ISP.
              </p>
            </div>
            <div className="p-3 pb-10">
              <div className="  grid grid-flow-col gap-0 items-center  bg-white text-black w-full m-auto mb-4">
                <h1 className="text-2xl tablet:text-5xl underline decoration-2 underline-offset-8 pb-4 p-4">
                  About CUSAT
                </h1>
                <a
                  className="tablet:ml-2 justify-self-end mr-4"
                  href="https://cusat.ac.in/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="  "
                    height="48"
                    width="48"
                  >
                    <path d="m24 40-2.1-2.15L34.25 25.5H8v-3h26.25L21.9 10.15 24 8l16 16Z" />
                  </svg>
                </a>
              </div>
              <img
                src={cusatimg}
                alt=""
                className=" h-[20%]  w-3/4 mobile:w-1/4   m-auto  pc:hidden   bg-white rounded-2xl p-2 mb-4   "
              />
              <p className="  text-justify pc:text-xl pt-4">
                CUSAT is an autonomous university owned by the state government.
                The university ranks at 69 in the Overall and 41 in the NIRF
                2022 University rankings. CUSAT has 3 campuses, 29 academic
                departments, and 7 centres. CUSAT provides education in the
                fields of Applied Science, Technology, Industry, Commerce,
                Management, and Social Sciences.
              </p>
            </div>
          </div>
        </div>
        <div className="pt-24 pc:pt-48 grid  w-screen h-fit  pc:mb-28 items-center font-defmono ">
          <h1 className="text-5xl mobile:text-6xl   underline-offset-8 grid justify-center snap-center font-defmono pt-10 border-t-[2px] mt-10 ">
            Events
          </h1>
          <div className="max-h-2 w-1/5 border-t-2 border-white place-self-center pb-10  "></div>
          <div className="grid grid-flow-row tablet:grid-flow-row gap-4 pc:grid-cols-3  place-items-center  mobile:pb-0 pc:mr-4 pc:pl-10 pc:pr-10  ">
            {/* 1 */}
            <div className="border-white mobile:w-auto max-w-[800px] pc:mr-4 pc:ml-6 m-auto mobile:h-auto pc:h-[500px] content-start  grid place-items-center w-[320px]  mobile:pb-0     ">
              <div
                className="border-t-2 border-l-2 border-r-2 grid place-items-center rounded-xl overflow-clip"
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
              >
                <div className=" mobile:w-3/4 grid place-items-center">
                  <img
                    className="h-[200px] w-auto"
                    src={glowinthedark}
                    mb-20
                    alt="test"
                  />
                </div>
                <div className="p-4 bg-white  text-black">
                  <h1 className="text-xl  underline underline-offset-4 pb-4">
                    Glow in the Dark
                  </h1>
                  <p className=" text-left text-lg">
                    We work in the darkness to serve the Light", said Ezio in
                    Assassins Creed but what truly brings out light during the
                    darkness is UV light shone on fluorescent materials. Immerse
                    yourself in this enthralling experience as photonics works
                    in the dark to "serve" the light.
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="border-white mobile:w-auto max-w-[800px] pc:mr-4 pc:ml-6 m-auto mobile:h-auto pc:h-[500px] content-start  grid place-items-center w-[320px]  mobile:pb-0    ">
              <div
                className="border-t-2 border-l-2 border-r-2 grid place-items-center rounded-xl overflow-clip  "
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
              >
                <div className=" mobile:w-3/4 grid place-items-center">
                  <img
                    className="h-[200px] w-auto"
                    src={football}
                    mb-20
                    alt="test"
                  />
                </div>
                <div className="p-4  bg-white  text-black    ">
                  <h1 className="text-xl  underline underline-offset-4 pb-4 ">
                    Neon Football
                  </h1>
                  <p className=" text-left text-lg">
                    In this radiant and colorful season of football it would be
                    nothing short of a crime not to present an ode to the game
                    that conquered a million hearts. We have tried to merge our
                    passion of light and football to create "Neon football"
                    immerse yourself in this game of mind and body.
                  </p>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="border-white mobile:w-auto max-w-[800px] pc:mr-4 pc:ml-6 m-auto mobile:h-auto pc:h-[500px] content-start  grid place-items-center w-[320px]  mobile:pb-0     ">
              <div
                className="border-t-2 border-l-2 border-r-2 grid place-items-center rounded-xl overflow-clip"
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
              >
                <div className=" mobile:w-3/4 grid place-items-center">
                  <img
                    className="h-[200px] w-auto"
                    src={carousel}
                    mb-20
                    alt="test"
                  />
                </div>
                <div className="p-4  bg-white  text-black">
                  <h1 className="text-xl  underline underline-offset-4 pb-4">
                    Photon Carousal
                  </h1>
                  <p className=" text-left text-lg">
                    The students who study the phenomenon responsible for the
                    formations of colours couldn't be any less colourful
                    themselves. Come witness the plethora of culturally
                    multifaceted exuberant student community of ISP as they
                    paint a visual masterpiece.
                    <p className="text-white invisible">akdjhsfjhafjshjfhj </p>
                  </p>
                </div>
              </div>
            </div>
            {/* 3 end */}
            {/* end */}
          </div>
        </div>
      </div>
      <div className=" w-full h-[50vh] tablet:h-screen grid text-center items-center justify-center m-auto pb-10 ">
        <h1 className="text-4xl pc:text-5xl underline underline-offset-[24px] m-4 pb-10 snap-center font-defmono  ">
          Our Sponsors
        </h1>
        <Swiper
          spaceBetween={50}
          navigation={true}
          autoplay
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          className="w-full external-buttons place-items-center  "
        >
          <SwiperSlide>
            <div className="grid w-full h-full  place-items-center content-start ">
              <img
                src={derriklogo}
                alt=""
                className="bg-white w-[75%] pc:w-2/4 pc:max-w-4xl pl-20 pr-20"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid w-full h-full  place-items-center content-start ">
              <img
                src={spielogo}
                alt=""
                className="bg-white w-[75%] pc:w-2/4 pc:max-w-4xl"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid  w-full h-full place-items-center  ">
              <img
                src={opticalogo}
                alt=""
                className="bg-white w-[75%] pc:w-2/4 pc:max-w-4xl"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid  w-full h-full place-items-center  ">
              <img
                src={ieeelogo}
                alt=""
                className="bg-white w-[75%] pc:w-2/4 pc:max-w-4xl"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid  w-full h-full place-items-center  ">
              <img
                src={wonderlalogo}
                alt=""
                className="bg-white w-[75%] pc:w-2/4 pc:max-w-4xl"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid w-full h-full  place-items-center content-start ">
              <img
                src={decathlonlogo}
                alt=""
                className="bg-white w-[75%] pc:w-2/4 pc:max-w-4xl p-8 tablet:p-20 mt-10 tablet:mt-20"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid  w-full h-full place-items-center pc:pt-14  ">
              <img
                src={cditlogo}
                alt=""
                className="bg-white w-[75%] p-10 pc:w-2/4 pc:max-w-4xl"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className=" border-t-2 border-white h-fit w-full grid mb-4 font-defmono  ">
        <h1
          id="contact"
          className="text-center pt-4 text-3xl pc:text-4xl underline underline-offset-8 pb-4 "
        >
          CONTACT US!
        </h1>
        <div className="grid grid-flow-row pc:grid-flow-col justify-around   ">
          <div className="grid grid-flow-row text-xl pc:text-2xl p-auto pl-4 items-center w-fit rounded-xl m-2 p-2 h-[90%] d">
            <p className=" underline underline-offset-8 pl-4 text-2xl pc:text-3xl">
              Office
            </p>
            <a
              href="http://photonics.cusat.ac.in/contact-us/"
              className="hover:scale-105 transition duration-200  p-2 w-fit"
            >
              The Director,
              <br />
              International School of Photonics, <br />
              Cochin University of Science and Technology, <br />
              South Kalamassery, <br />
              Cochin -22.
            </a>
          </div>
          <div className="grid grid-flow-row text-xl pc:text-2xl p-auto pl-4 items-center w-fit rounded-xl m-2 p-2 h-[90%] ">
            <p className=" underline underline-offset-8  text-2xl pc:text-3xl">
              Student Committee
            </p>
            <div className="text-left grid grid-flow-row pt-4 gap-2">
              <a
                href="tel:628-228-4183"
                className="hover:scale-110 transition duration-200 w-fit"
              >
                Amal IS : +91 62822 84183
              </a>
              <a
                className="hover:scale-110 transition duration-200 w-fit"
                href="tel:949-743-4956"
              >
                Aavani V : +91 94974 34956
              </a>
              <a
                className="hover:scale-110 transition duration-200 w-fit"
                href="tel:735-681-3954"
              >
                Kavyasree AK : +91 73568 13954
              </a>
            </div>
          </div>
        </div>

        <p className="text-2xl pc:text-2xl text-center border-t-2 border-white pt-2  ">
          Copyright <span className="text-red-600">ETCHNEW</span> 2023
        </p>
      </div>
    </div>
  );
}

export default Home;
