import React from "react";
import Footer from "./footer";

function Events() {
  return (
    <div className="bg-black font-defmono text-white grid items-center w-full">
      <h1 className="text-center text-8xl pt-20 underline decoration-1 tablet:underline-offset-[12px] underline-offset-8 pb-8">
        Events
      </h1>
      <div className="w-full mobile:h-auto pc:h-screen  text-center text-2xl">
        <p>
          The most anticipated event of all time "EtchNew'23" is finally here.
          <br />
          It will be held in four segments
        </p>
        <div className="grid grid-flow-row pc:grid-flow-col mx-4 text-center pc:divide-x  ">
          <div className="m-2 pl-2 pr-2">
            <h1 className="text-4xl underline underline-offset-8 decoration-1 pb-10   ">
              Workshops
            </h1>
            <p className="text-justify">
              A hands on interactive experience with various setups from basic
              LEDs to advanced lasers,fiber drawing which will be open to all
              students.
            </p>
          </div>
          <div className="m-2 pl-2 pr-2">
            <h1 className="text-4xl underline underline-offset-8 decoration-1 pb-10   ">
              Lectures
            </h1>
            <p className="text-justify">
              Lecture series from prominent professors with live demonstrations
              of physical phenomenons.
            </p>
          </div>
          <div className="m-2 pl-2 pr-2">
            <h1 className="text-4xl underline underline-offset-8 decoration-1 pb-10   ">
              Games
            </h1>
            <p className="text-justify">
              Experience the world in yet another immersive world of virtual
              reality set in the dark rooms, Engaging quizes, working model
              competition and games will be unveiling on the day of the event
              with spot registration for students to compete for and win
              exciting prizes!
            </p>
          </div>
          <div className="m-2 pl-2 pr-2">
            <h1 className="text-4xl underline underline-offset-8 decoration-1 pb-10   ">
              Exhibition
            </h1>
            <p className="text-justify">
              Our flagship program for Etchnew'23 "The Optic Exhibition" an
              immersive and entertaining experience into the field of Optics and
              Photonics. The program would be a visual experience through
              various experiments showcasing in dark rooms. The exhibition would
              be open till the end of the event.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Events;
